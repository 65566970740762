import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EventStatus } from 'betweb-openapi-axios'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import {
  StyledEventCardProbabilitiesSuspendedText,
  StyledEventCardProbabilitiesSuspendedWrapper,
  StyledIconEventProbabilitiesSuspended
} from './EventCardProbabilitiesSuspended.styled'
import { EventCardProbabilitiesSuspendedProps } from './EventCardProbabilitiesSuspended.types'

export const EventCardProbabilitiesSuspended: FC<
  EventCardProbabilitiesSuspendedProps
> = ({ event }) => {
  const [t] = useTranslation()

  const isSuspended = event.status === EventStatus.Suspended

  const handleClick = (e) => e.stopPropagation()

  return isSuspended ? (
    <StyledEventCardProbabilitiesSuspendedWrapper onClick={handleClick}>
      <StyledIconEventProbabilitiesSuspended
        colorProps={{
          type: EColorsTypes.CUSTOM,
          name: EColorsNames.Primary,
          value: 12
        }}
        size={14}
      />
      <StyledEventCardProbabilitiesSuspendedText>
        {t('odds update')}
      </StyledEventCardProbabilitiesSuspendedText>
    </StyledEventCardProbabilitiesSuspendedWrapper>
  ) : null
}
