import { useEffect, useRef, useState } from 'react'

export const useHandleWidthTeamNames = () => {
  const [isLongTeamName, setIsLongTeamName] = useState<boolean>(false)
  const refTeamHome = useRef<HTMLDivElement | null>(null)
  const refTeamAway = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const nodeTeamHome = refTeamHome.current
    const nodeTeamAway = refTeamAway.current

    if (nodeTeamHome && nodeTeamAway) {
      if (
        nodeTeamHome.scrollWidth > nodeTeamHome.clientWidth ||
        nodeTeamAway.scrollWidth > nodeTeamAway.clientWidth
      ) {
        setIsLongTeamName(true)
      }
    }
  }, [])

  return { isLongTeamName, refTeamHome, refTeamAway }
}
