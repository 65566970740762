import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

export const StyledSearchList = styled.div`
  ${(props) => css`
    background-color: ${props.theme.colors.custom.primary[10]};
    border-radius: 8px;
    box-shadow: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
      ? `0px 2px 4px  rgb(0 0 0 / 60%)`
      : `0px 2px 8px  rgb(0 0 0 / 8%)`};
    height: 100%;
    left: 0;
    margin-top: 4px;
    min-height: 232px;
    min-width: 640px;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
  `}
`

export const StyledSearchListHeaderText = styled.div`
  padding: 0 16px;
  ${({ theme }) =>
    `
    height: 24px;
    line-height: ${theme.fonts.size.m};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[9]};
    display: flex;
    align-items: center;
  `}
`

export const StyledSearchItem = styled(Link)`
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  padding: 6px 16px;
  white-space: nowrap;
  ${({ theme }) =>
    `
    line-height: ${theme.fonts.size.xl};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[0]};
    &:hover {
      background-color: ${theme.colors.custom.primary[4]};
    }
  `}
`

export const StyledSearchItemLive = styled.span`
  margin-left: 4px;
  ${({ theme }) =>
    `
    line-height: ${theme.fonts.size.l};
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.red[50]};
  `}
`
