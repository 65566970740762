import * as ogCore from 'og-core/providers/main-page'
import * as astraCore from 'astra-core/containers/MainPageProvider'

import { IconLiveFilled } from 'shared/ui/Icon/General/IconLiveFilled'
import { IconLineFilled } from 'shared/ui/Icon/General/IconLineFilled'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'

import {
  EMainPageEventsList,
  MainPageEventsConfiguration,
  EMainPageEventsRepresentation
} from './MainPageEvents.types'

export const mainPageEventsConfiguration: MainPageEventsConfiguration = {
  [EMainPageEventsList.TOP_LIVE]: {
    selectCurrentSport: ogCore.selectTopLiveEventCurrentSportId,
    selectSports: ogCore.selectTopLiveEventsSports,
    selectEvents: ogCore.selectTopLiveEventsEnriched,
    selectLoading: ogCore.selectTopLiveEventsLoading,
    setCurrentSport: ogCore.mainPageProviderActions.selectTopLiveEventsSport,
    untranslatedTitle: 'mainPage topLive',
    icon: IconLiveFilled,
    representation: EMainPageEventsRepresentation.CAROUSEL,
    link: '/live',
    selectIsEventsIdsBySport: ogCore.selectIsTopLiveEventsCurrentSportId
  },
  [EMainPageEventsList.TOP_LINE]: {
    selectCurrentSport: ogCore.selectTopLineEventsCurrentSportId,
    selectSports: ogCore.selectTopLineEventsSports,
    selectEvents: ogCore.selectTopLineEventsEnriched,
    selectLoading: ogCore.selectTopLineEventsLoading,
    setCurrentSport: ogCore.mainPageProviderActions.selectTopLineEventsSport,
    untranslatedTitle: 'mainPage topLine',
    icon: IconLineFilled,
    representation: EMainPageEventsRepresentation.CAROUSEL,
    link: '/line',
    selectIsEventsIdsBySport: ogCore.selectIsTopLineEventsIdsBySport
  },
  [EMainPageEventsList.UPCOMING]: {
    selectCurrentSport: ogCore.selectUpcomingEventsCurrentSportId,
    selectSports: ogCore.selectUpcomingEventsSports,
    selectEvents: ogCore.selectUpcomingEvents,
    selectLoading: ogCore.selectUpcomingEventsLoading,
    setCurrentSport: ogCore.mainPageProviderActions.selectUpcomingEventsSport,
    untranslatedTitle: 'mainPage upcoming',
    representation: EMainPageEventsRepresentation.TABLE,
    link: '/live',
    selectIsEventsIdsBySport: ogCore.selectIsUpcomingEventsIdsBySport
  }
}

export const mainPageEventsConfigurationDeprecated: MainPageEventsConfiguration =
  {
    [EMainPageEventsList.TOP_LIVE]: {
      selectCurrentSport: astraCore.selectTopLiveEventCurrentSportId,
      selectSports: astraCore.selectTopLiveEventsSports,
      selectEvents: astraCore.selectTopLiveEventsEnriched,
      selectLoading: astraCore.selectTopLiveEventsLoading,
      setCurrentSport:
        astraCore.mainPageProviderActions.selectTopLiveEventsSport,
      untranslatedTitle: 'mainPage topLive',
      icon: IconLiveFilled,
      representation: EMainPageEventsRepresentation.CAROUSEL,
      link: '/live',
      selectIsEventsIdsBySport: astraCore.selectIsTopLiveEventsCurrentSportId
    },
    [EMainPageEventsList.TOP_LINE]: {
      selectCurrentSport: astraCore.selectTopLineEventsCurrentSportId,
      selectSports: astraCore.selectTopLineEventsSports,
      selectEvents: astraCore.selectTopLineEventsEnriched,
      selectLoading: astraCore.selectTopLineEventsLoading,
      setCurrentSport:
        astraCore.mainPageProviderActions.selectTopLineEventsSport,
      untranslatedTitle: 'mainPage topLine',
      icon: IconLineFilled,
      representation: EMainPageEventsRepresentation.CAROUSEL,
      link: '/line',
      selectIsEventsIdsBySport: astraCore.selectIsTopLineEventsIdsBySport
    },
    [EMainPageEventsList.UPCOMING]: {
      selectCurrentSport: astraCore.selectUpcomingEventsCurrentSportId,
      selectSports: astraCore.selectUpcomingEventsSports,
      selectEvents: astraCore.selectUpcomingEvents,
      selectLoading: astraCore.selectUpcomingEventsLoading,
      setCurrentSport:
        astraCore.mainPageProviderActions.selectUpcomingEventsSport,
      untranslatedTitle: 'mainPage upcoming',
      representation: EMainPageEventsRepresentation.TABLE,
      link: '/live',
      selectIsEventsIdsBySport: astraCore.selectIsUpcomingEventsIdsBySport
    }
  }

export const EVENTS_TO_ONBOARDING_ATTRS_MAP = {
  [EMainPageEventsList.TOP_LINE]: EWelcomeOnboardingDataAttrs.TOP_LINE_SECTION,
  [EMainPageEventsList.TOP_LIVE]: EWelcomeOnboardingDataAttrs.TOP_LIVE_SECTION,
  [EMainPageEventsList.UPCOMING]: EWelcomeOnboardingDataAttrs.NEAREST_EVENTS
}
