import range from 'lodash/range'
import React, { FC } from 'react'

import {
  StyledLineTableRowWrapperSkeleton,
  StyledTableBetsHeadSkeleton,
  StyledTableMainRowLeftOnePlugSkeleton,
  StyledTableMainRowLeftOnePlugTwoSkeleton,
  StyledTableMainRowLeftOneSkeleton,
  StyledTableMainRowLeftSkeleton,
  StyledTableMainRowLeftTwoSkeleton,
  StyledTableMainRowRightSkeleton,
  StyledTableMainRowSkeleton,
  StyledTableRowOutcomeSkeletonWrapper,
  StyledTableRowOutcomesSkeleton,
  StyledTableRowOutcomesSkeletonWrapper
} from './EventsTableSkeleton.styled'

export const EventsTableSkeleton: FC = () => {
  return (
    <>
      <StyledTableBetsHeadSkeleton />
      {range(12).map((item) => (
        <StyledLineTableRowWrapperSkeleton key={item}>
          <StyledTableMainRowSkeleton>
            <StyledTableMainRowLeftSkeleton>
              <StyledTableMainRowLeftOneSkeleton>
                <StyledTableMainRowLeftOnePlugSkeleton />
                <StyledTableMainRowLeftOnePlugTwoSkeleton />
              </StyledTableMainRowLeftOneSkeleton>
              <StyledTableMainRowLeftTwoSkeleton />
            </StyledTableMainRowLeftSkeleton>
            <StyledTableMainRowRightSkeleton />
          </StyledTableMainRowSkeleton>
          <StyledTableRowOutcomesSkeletonWrapper>
            {range(14).map((item) => (
              <StyledTableRowOutcomeSkeletonWrapper key={item}>
                <StyledTableRowOutcomesSkeleton />
              </StyledTableRowOutcomeSkeletonWrapper>
            ))}
          </StyledTableRowOutcomesSkeletonWrapper>
        </StyledLineTableRowWrapperSkeleton>
      ))}
    </>
  )
}
