import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEventProbabilitiesSuspended: FC<IconProps> = ({
  size = 24,
  ...props
}) => (
  <Icon size={size} {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM10.8193 4.59602C11.2098 4.2055 11.2098 3.57233 10.8193 3.18181C10.4288 2.79129 9.79561 2.79129 9.40509 3.18181L6.92687 5.66003L5.68134 4.6636C5.25007 4.31859 4.62078 4.38851 4.27577 4.81978C3.93076 5.25104 4.00068 5.88033 4.43195 6.22534L6.37639 7.7809C6.77428 8.09921 7.34789 8.06744 7.70819 7.70713L10.8193 4.59602Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
