import { MainOutcomeTypes } from 'astra-core/containers/CommonDataProvider/types'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'

export const MAIN_TYPES: Partial<
  Record<ESportsCodes | 'default', Array<MainOutcomeTypes[]>>
> = {
  default: [
    [MainOutcomeTypes.W1, MainOutcomeTypes.W1_X2],
    [MainOutcomeTypes.WX],
    [MainOutcomeTypes.W2, MainOutcomeTypes.W2_X2]
  ],
  [ESportsCodes.TENNIS]: [
    [MainOutcomeTypes.W1_X2],
    [MainOutcomeTypes.WX],
    [MainOutcomeTypes.W2_X2]
  ]

  // If we specify 2 or more outcomeTypes and events of a certain sport do not require one of them, we can still randomly choose the unnecessary one using [].find. To avoid this, we explicitly state that a specific type of sport can only have a specific outcomeType.
}
