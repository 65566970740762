import React, { FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { formatDate, getEventHomeAwayCompetitors } from 'astra-core'
import { useGetEventLink } from 'og-web-core/utils/events'

import { ETestData } from 'shared/lib/testData'
import { routesWithoutPrevPath } from 'features/event/row/open-event-page/lib'
import {
  EEventStatisticsCodes,
  EventStatistics
} from 'widgets/event-statistics'
import { useHandleWidthTeamNames } from 'pages/page-main/Main/components/EventCard/hooks'
import { Tooltip } from 'shared/ui/Tooltip'

import { EventCardProps } from './EventCard.types'
import {
  EventCardButtons,
  EventCardCompetitor,
  EventCardProbabilities
} from './components'
import {
  StyledEventCardWrapper,
  StyledEventCardHeaderWrapper,
  StyledEventCardHeader,
  StyledEventCardMetaWrapper,
  StyledEventCardDate,
  StyledEventCardName,
  AdditionalInfoWrapper,
  StyledEventCardSkeletonWrapper
} from './EventCard.styled'

export const EventCard: FC<EventCardProps> = ({ event }) => {
  const history = useHistory()
  const { isLongTeamName, refTeamAway, refTeamHome } = useHandleWidthTeamNames()

  const { homeCompetitors, awayCompetitors } = useMemo(
    () => getEventHomeAwayCompetitors(event),
    [event]
  )
  const eventLink = useGetEventLink({ event, routesWithoutPrevPath })

  const goToEventPage = useCallback(() => {
    history.push(eventLink.pathname, eventLink.state)
  }, [eventLink, history])

  const currentServer = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.CurrentServer
  )

  return (
    <StyledEventCardWrapper
      data-test-id={`${ETestData.TestEvent}-${event.id}`}
      onClick={goToEventPage}
    >
      <StyledEventCardMetaWrapper>
        <StyledEventCardHeaderWrapper>
          <StyledEventCardHeader>
            <Tooltip title={event.tournament.name}>
              <StyledEventCardName>{event.tournament.name}</StyledEventCardName>
            </Tooltip>
            <EventCardButtons event={event} />
          </StyledEventCardHeader>

          <EventCardCompetitor
            competitors={homeCompetitors}
            isLongTeamName={isLongTeamName}
            isServer={currentServer?.value === '1'}
            ref={refTeamHome}
          />

          <EventCardCompetitor
            competitors={awayCompetitors}
            isLongTeamName={isLongTeamName}
            isServer={currentServer?.value === '2'}
            ref={refTeamAway}
          />
        </StyledEventCardHeaderWrapper>
      </StyledEventCardMetaWrapper>

      <AdditionalInfoWrapper>
        {event.live ? (
          <EventStatistics event={event} showLiveIcon />
        ) : (
          <StyledEventCardDate>
            {formatDate(event.eventDate)}
          </StyledEventCardDate>
        )}
      </AdditionalInfoWrapper>

      <EventCardProbabilities event={event} />
    </StyledEventCardWrapper>
  )
}

export const EventCardSkeleton: FC = () => <StyledEventCardSkeletonWrapper />
