import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLineFilled: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 22 16"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2H17M2 8H20M2 14H15"
        stroke="#43B243"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </svg>
  </Icon>
)
