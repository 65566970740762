import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLiveFilled: FC<IconProps> = ({ size = 24, ...props }) => (
  <Icon size={size} {...props}>
    <svg
      fill="none"
      height="18"
      viewBox="0 0 24 18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5113 0.719482C20.8169 0.79919 20.9896 1.0516 21.1755 1.27743C22.7298 3.13727 23.673 5.26279 23.9254 7.68058C24.2974 11.2276 23.2745 14.3494 20.9231 17.0329C20.6442 17.3517 20.3652 17.365 20.0729 17.0595C19.7674 16.7539 19.4618 16.4484 19.1563 16.1428C18.8508 15.8373 18.8508 15.6114 19.1297 15.2793C20.3386 13.8845 21.1224 12.2903 21.3881 10.457C21.7999 7.5743 21.056 5.01039 19.1696 2.79187C18.8242 2.39333 18.8375 2.19407 19.1962 1.83538C19.4618 1.56969 19.7275 1.31729 19.9932 1.03831C20.1261 0.892181 20.2722 0.785905 20.4582 0.719482C20.4848 0.719482 20.498 0.719482 20.5113 0.719482Z"
        fill="#E03A3A"
      />
      <path
        d="M3.53369 0.719482C3.79938 0.785905 3.97208 0.958604 4.15806 1.14459C4.37061 1.37042 4.60973 1.59626 4.83557 1.8221C5.22082 2.20735 5.22082 2.39333 4.87542 2.79187C3.73295 4.09375 2.98902 5.58162 2.68348 7.28204C2.17866 10.2445 2.89603 12.8748 4.82229 15.1863C5.22082 15.6646 5.22082 15.8107 4.76915 16.2491C4.49017 16.5281 4.2112 16.8203 3.91894 17.086C3.65325 17.3384 3.37427 17.3252 3.13515 17.0595C1.2886 14.9871 0.252406 12.5693 0.0531381 9.79282C0.0132845 9.71311 0.0664227 9.62012 0.0132845 9.54041C0.0132845 9.42085 0.0132845 9.31458 0.0132845 9.19502C0.0531381 9.06217 0.0531381 8.92933 0.0132845 8.80976C0.0132845 8.79648 0.0132845 8.7832 0.0132845 8.75663C0.0664227 8.66363 0.0265691 8.55736 0.0398536 8.46437C0.0398536 8.38466 0.0398536 8.31824 0.0398536 8.23853C0.0398536 6.76395 0.43839 5.38235 1.03619 4.04062C1.54101 2.89815 2.2318 1.87524 3.05544 0.932035C3.175 0.79919 3.32113 0.759336 3.46726 0.719482C3.49383 0.719482 3.50712 0.719482 3.53369 0.719482Z"
        fill="#E03A3A"
      />
      <path
        d="M0.0132845 8.80976C0.0929918 8.94261 0.0929918 9.07545 0.0132845 9.19502C0.0132845 9.07545 0.0132845 8.94261 0.0132845 8.80976Z"
        fill="#E03A3A"
      />
      <path
        d="M0.0531381 8.46437C0.0531381 8.57064 0.0929918 8.67692 0.0132845 8.75663C0.0132845 8.66363 0.0132845 8.55736 0.0132845 8.46437C0.0265691 8.45108 0.0398536 8.45108 0.0531381 8.46437Z"
        fill="#E03A3A"
      />
      <path
        d="M0.0531381 8.46437C0.0398536 8.46437 0.0265691 8.46437 0.0132845 8.46437C0.0132845 8.38466 0.0132845 8.30495 0.0132845 8.22524C0.0265691 8.22524 0.0398536 8.22524 0.0531381 8.22524C0.0531381 8.30495 0.0531381 8.38466 0.0531381 8.46437Z"
        fill="#E03A3A"
      />
      <path
        d="M0.0132845 9.54041C0.0929918 9.60684 0.0664227 9.69983 0.0531381 9.79282H0C0.0132845 9.71311 0.0132845 9.62012 0.0132845 9.54041Z"
        fill="#E03A3A"
      />
      <path
        d="M12.0225 11.9981C10.3619 11.9981 9.0202 10.6563 9.0202 8.99575C9.0202 7.34847 10.3619 6.02001 12.0092 6.00673C13.6698 6.00673 15.0115 7.34847 15.0115 9.00903C15.0115 10.6696 13.6831 11.9981 12.0225 11.9981Z"
        fill="#E03A3A"
      />
      <path
        d="M19.515 8.99575C19.4884 10.8024 18.9305 12.3966 17.788 13.7782C17.4692 14.1634 17.2035 14.1767 16.8581 13.8313C16.5525 13.5391 16.2603 13.2335 15.9547 12.9413C15.6758 12.6623 15.6625 12.4497 15.9016 12.1442C17.3895 10.2046 17.4027 7.78686 15.9149 5.87388C15.6758 5.56834 15.689 5.35579 15.9547 5.07681C16.2603 4.75798 16.5791 4.45244 16.8846 4.14689C17.2035 3.84135 17.4825 3.84135 17.7747 4.18675C18.9305 5.59491 19.5017 7.20234 19.515 8.99575Z"
        fill="#E03A3A"
      />
      <path
        d="M4.51674 9.00903C4.54331 7.22891 5.10126 5.63476 6.23045 4.25317C6.56256 3.84135 6.82825 3.82806 7.20022 4.20003C7.50576 4.49229 7.79802 4.79783 8.09028 5.10338C8.35597 5.36907 8.35597 5.58162 8.13014 5.87388C6.62898 7.82671 6.62898 10.1914 8.13014 12.1575C8.35597 12.4497 8.34269 12.6623 8.077 12.928C7.75817 13.2468 7.45262 13.5656 7.1338 13.8712C6.84154 14.1634 6.54928 14.1501 6.28359 13.8313C5.12783 12.4364 4.54331 10.829 4.51674 9.00903Z"
        fill="#E03A3A"
      />
    </svg>
  </Icon>
)
