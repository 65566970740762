import React, {
  FC,
  useRef,
  useCallback,
  RefObject,
  useEffect,
  useMemo
} from 'react'
import throttle from 'lodash/throttle'

import { CarouselWrapper, CarouselItem } from './styled'
import { CarouselProps } from './types'

const ROOT_MARGIN = '0px 16px'
const THRESHOLD = 1
const SCROLL_THROTTLING_MS = 300
const CONTAINER_PADDING = 16

/*
  * Pass children as array of ReactElements and define keys there
  * rootMargin -> pass to control intersection field for an IntesectionObserver instance
  * threshold -> value to control scroll frequency, need to Safary due to use can click faster than
                 IntersectionObserver track itersectionin (beacuse of 'smooth' scroll behavior)
  * containerPadding -> pass to control left offset for items (wrapper left position + padding )
*/
export const Carousel: FC<CarouselProps> = ({
  children,
  className,
  onCarouselMounted,
  onIndexChange,
  rootMargin = ROOT_MARGIN,
  threshold = THRESHOLD,
  containerPadding = CONTAINER_PADDING,
  scrollThrottlingMs = SCROLL_THROTTLING_MS
}) => {
  const childrenArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  )

  const wrapperRef = useRef<HTMLDivElement>(null)
  const itemsRefs: RefObject<HTMLDivElement>[] = useMemo(
    () => childrenArray.map(() => React.createRef()),
    [childrenArray]
  )

  const observer = useRef<IntersectionObserver | null>(null)
  const positions = useRef<number[]>()
  const currentLeftIndex = useRef(0)
  const currentRightIndex = useRef(0)
  const isScrollable = useRef(false)

  const handleIntersection = useCallback(
    (entry: IntersectionObserverEntry) => {
      const { isIntersecting } = entry
      const entryIndex = Number(entry.target.getAttribute('data-index'))

      // Some element intersected the view
      if (isIntersecting) {
        if (entryIndex > currentRightIndex.current) {
          currentRightIndex.current = entryIndex // Right element intersected the view
        } else if (entryIndex < currentLeftIndex.current) {
          currentLeftIndex.current = entryIndex // Left element intersected the view
        }
      }

      // Some element left the view
      if (!isIntersecting) {
        if (
          entryIndex === currentLeftIndex.current &&
          entryIndex <= currentRightIndex.current
        ) {
          currentLeftIndex.current = entryIndex + 1 // Left element left the view
          if (currentLeftIndex.current > currentRightIndex.current) {
            currentRightIndex.current = currentLeftIndex.current
          }
        } else if (entryIndex <= currentRightIndex.current) {
          currentRightIndex.current = entryIndex - 1 // Right element left the view
        }
      }
      onIndexChange?.(currentLeftIndex.current)
    },
    [onIndexChange]
  )

  useEffect(() => {
    positions.current = []
    currentLeftIndex.current = 0
    currentRightIndex.current = 0

    if (IntersectionObserver) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach(handleIntersection)
        },
        {
          threshold,
          rootMargin,
          root: wrapperRef.current
        }
      )
    }

    const wrapperElement = wrapperRef.current
    const wrapperRect = wrapperElement?.getBoundingClientRect()

    const observerInstance = observer.current

    itemsRefs.forEach((itemRef, index) => {
      const itemElement = itemRef.current

      if (itemElement && observerInstance && wrapperRect) {
        observerInstance.observe(itemElement)
        const itemRect = itemElement.getBoundingClientRect()

        const leftOffset = wrapperRect.x + containerPadding // Sidebar as an example
        let elementsGap = 0 // We need to take in account gap between items

        // Start with second element in a carousel
        if (index > 0) {
          const leftElementRect =
            itemsRefs[index - 1].current!.getBoundingClientRect()

          // Take a left element right side position as X pos + width as ABS
          const leftCoordinate = Math.abs(leftElementRect.right)

          // Take a right element left position as X pos as ABS
          const rightCoordinate = Math.abs(itemRect.left)

          // Difference between them is a gap
          if (leftCoordinate > rightCoordinate) {
            elementsGap = leftCoordinate - rightCoordinate
          } else {
            elementsGap = rightCoordinate - leftCoordinate
          }
        }

        const elementLeftSidePpostition = index * (itemRect.width + elementsGap)
        positions.current?.push(leftOffset + elementLeftSidePpostition)
      }
    })
    return () => {
      if (observerInstance) {
        observerInstance.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleIntersection, rootMargin, threshold, itemsRefs.length])

  const scrollToIndex = useMemo(
    () =>
      throttle((index: number) => {
        const wrapperElement = wrapperRef.current
        const wrapperRect = wrapperElement?.getBoundingClientRect()

        if (wrapperElement && wrapperRect) {
          const position = positions.current && positions.current[index]

          let offset = wrapperRect.x + containerPadding
          if (position) {
            offset = position - offset
          }

          wrapperElement.scrollTo({ left: offset, behavior: 'smooth' })
        }
      }, scrollThrottlingMs),
    [positions, containerPadding, scrollThrottlingMs]
  )

  const scrollToNext = useCallback(() => {
    if (currentRightIndex.current < itemsRefs.length - 1) {
      scrollToIndex(currentLeftIndex.current + 1)
    }
  }, [scrollToIndex, itemsRefs])

  const scrollToPrevious = useCallback(() => {
    if (currentLeftIndex.current !== 0) {
      scrollToIndex(currentLeftIndex.current - 1)
    }
  }, [scrollToIndex])

  useEffect(() => {
    if (wrapperRef.current) {
      isScrollable.current =
        wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth
    }
  }, [onCarouselMounted])

  useEffect(() => {
    if (wrapperRef.current) {
      onCarouselMounted?.({
        scrollTo: scrollToIndex,
        scrollToNext,
        scrollToPrevious,
        isScrollable: !!isScrollable.current
      })
    }
  }, [
    onCarouselMounted,
    scrollToIndex,
    scrollToNext,
    scrollToPrevious,
    isScrollable
  ])
  return (
    <CarouselWrapper className={className} ref={wrapperRef}>
      {childrenArray.map((child, index) => (
        // Due to typing error
        // @ts-ignore
        <CarouselItem data-index={index} key={child.key} ref={itemsRefs[index]}>
          {child}
        </CarouselItem>
      ))}
    </CarouselWrapper>
  )
}
