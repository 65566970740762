import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { css, keyframes } from 'styled-components'

const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`
const TableHeadShine = keyframes`
  0%, 30% {
    left: -100%;
  }
  70%, 100% {
    left: 150%;
  }
`

export const StyledTableBetsHeadSkeleton = styled.div`
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 24px;
  margin: 8px 13px 0;
  overflow: hidden;
  position: relative;

  &::before {
    animation: ${TableHeadShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`

export const StyledLineTableRowWrapperSkeleton = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.custom.primary[4]};
  display: flex;
  height: 41px;
`

export const StyledTableMainRowSkeleton = styled.div`
  border-right: 1px solid ${(p) => p.theme.colors.custom.primary[4]};
  display: flex;
  height: 100%;
  padding: 4px 8px;
  width: 50%;
`
export const StyledTableMainRowLeftSkeleton = styled.div`
  display: flex;
  gap: 4px;
  height: 100%;
  width: 50%;
`

export const StyledTableMainRowLeftOneSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const StyledTableMainRowLeftOnePlugSkeleton = styled.div`
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 14px;
  overflow: hidden;
  position: relative;
  width: 31px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`
export const StyledTableMainRowLeftOnePlugTwoSkeleton = styled(
  StyledTableMainRowLeftOnePlugSkeleton
)`
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  height: 16px;
  width: 31px;
`

export const StyledTableMainRowLeftTwoSkeleton = styled.div`
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 14px;
  overflow: hidden;
  position: relative;
  width: 98px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`

export const StyledTableMainRowRightSkeleton = styled.div`
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 16px;
  margin-top: auto;
  width: inherit;
`

export const StyledTableRowOutcomesSkeletonWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 50%;
`

export const StyledTableRowOutcomeSkeletonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  &:not(:nth-child(7)):not(:nth-child(9)):not(:nth-child(11)):not(
      :nth-child(14)
    ) {
    border-right: 1px solid ${(p) => p.theme.colors.custom.primary[4]};
  }
  &:not(:nth-child(12)) {
    height: 100%;
  }
  &:nth-child(12) {
    height: 28px;
  }
`

export const StyledTableRowOutcomesSkeleton = styled.div`
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 4px;
  height: 14px;
  overflow: hidden;
  position: relative;
  width: 31px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`
