import styled, { css, keyframes } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { SportIcon } from 'shared/ui/Icon/SportIcon/SportIcon'

import { StyledSportsListItemProps } from './SportsList.types'

export const StyledSportsListWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 0.25rem 1rem;
`

export const StyledSportsListItem = styled.div<StyledSportsListItemProps>`
  align-content: center;
  align-items: center;
  background-color: ${({ bgColor, isActive }) => (isActive ? bgColor : '')};
  border-radius: 0.25rem;
  color: ${(p) =>
    p.isActive
      ? p.theme.colors.default.primary[90]
      : p.theme.colors.default.primary[50]};
  column-gap: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.25rem 0.5rem;
`

const SkeletonShine = keyframes`
  0%, 27% {
    left: -100%;
  }
  50%, 100% {
    left: 150%;
  }
`

export const StyledSportsListItemSkeleton = styled.div`
  align-items: center;
  background-color: ${(p) => p.theme.colors.custom.primary[4]};
  border-radius: 0.25rem;
  column-gap: 0.25rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  height: 32px;
  margin-right: 0.25rem;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  position: relative;
  width: 90px;

  &::before {
    animation: ${SkeletonShine} 3.5s infinite linear;
    content: '';
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${theme.typeLayoutTheme === LayoutTheme.Dark
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.4)'},
        rgba(255, 255, 255, 0)
      );
    `};
    width: 70%;
  }
`

export const StyledSportsListItemLabel = styled.div`
  font-size: ${(p) => p.theme.fonts.size.m};
  white-space: nowrap;
`

export const StyledSportsListItemIcon = styled(
  SportIcon
)<StyledSportsListItemProps>`
  background-color: transparent;
  margin: 4px 0;

  path {
    fill: ${(p) => !p.isActive && p.theme.colors.default.primary[50]};
  }
`

export const StyledSportsWrapper = styled.div`
  -ms-overflow-style: none;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-x: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
