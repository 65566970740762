import styled, { css } from 'styled-components'

import { IconEventProbabilitiesSuspended } from 'shared/ui/Icon/General/IconEventProbabilitiesSuspended'

export const StyledEventCardProbabilitiesSuspendedWrapper = styled.div`
  align-items: center;
  border-radius: 24px;
  bottom: 0;
  cursor: default;
  display: flex;
  gap: 4px;
  height: 24px;
  justify-content: center;
  left: 0;
  margin: auto;
  padding: 0 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: max-content;
  z-index: 1;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[4]};
    `};
`

export const StyledEventCardProbabilitiesSuspendedText = styled.div`
  line-height: 16px;
  padding: 0 1px;
  user-select: none;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[12]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledIconEventProbabilitiesSuspended = styled(
  IconEventProbabilitiesSuspended
)`
  margin: 1px;
`
