import React, { MouseEvent, FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectOutcomeTypes,
  selectMainOutcomeTypes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useProbs } from 'astra-core/hooks/useEvents'
import { getBetOutcomeId } from 'astra-core'
import { EventStatus } from 'betweb-openapi-axios'

import { getMainOutcomeTypeProbability } from 'shared/lib/events'
import { useEventMode } from 'hooks'
import { RootState } from 'shared/types/store'
import { useHandleAddCoefficientToBasket } from 'features/event'

import {
  StyledEventCardProbabilities,
  StyledEventCardProbabilitiesItem,
  StyledEventCardProbabilitiesItemCode,
  StyledEventCardProbabilitiesItemValue,
  StyledEventCardProbabilitiesItemWrapper
} from './EventCardProbabilities.styled'
import {
  EventCardProbabilitiesProps,
  EventCardProbabilityCellProps
} from './EventCardProbabilities.types'
import { MAIN_TYPES } from './constants'
import { EventCardProbabilitiesSuspended } from './EventCardProbabilitiesSuspended'

export const EventCardProbabilities: FC<EventCardProbabilitiesProps> = ({
  event
}) => {
  const sportData = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )

  const typesMap = MAIN_TYPES[sportData?.code || ''] || MAIN_TYPES.default

  return (
    <StyledEventCardProbabilities>
      <EventCardProbabilitiesSuspended event={event} />

      {typesMap.map((type) => (
        <ProbabilityCell
          cardMainOutcomeTypes={type}
          event={event}
          key={type[0]}
        />
      ))}
    </StyledEventCardProbabilities>
  )
}

const ProbabilityCell: FC<EventCardProbabilityCellProps> = ({
  cardMainOutcomeTypes,
  event
}) => {
  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket()
  const mainOutcomeTypes = useSelector(selectMainOutcomeTypes)
  const outcomeTypes = useSelector(selectOutcomeTypes)
  const probs = useProbs(event)
  const isSuspended = event.status === EventStatus.Suspended

  const getOutcomeProbability = useCallback(
    (outcomeTypeName) =>
      getMainOutcomeTypeProbability({
        outcomeType: outcomeTypeName,
        probs,
        mainOutcomeTypes,
        eventId: event.id
      }),
    [event.id, mainOutcomeTypes, probs]
  )

  const eventProbability = useMemo(() => {
    const matchingMainOutcomeType = cardMainOutcomeTypes.find(
      (outcomeTypeName) => getOutcomeProbability(outcomeTypeName)
    )

    return getOutcomeProbability(matchingMainOutcomeType)
  }, [getOutcomeProbability, cardMainOutcomeTypes])

  const onClickAddOutcomeToBasket = useCallback(
    (clickEvent: MouseEvent) => {
      clickEvent.stopPropagation()
      handleAddOutcomeToBasket({
        eventProbability,
        eventId: event.id,
        eventStatus: event.status,
        live: event.live
      })
    },
    [
      event.id,
      event.live,
      event.status,
      eventProbability,
      handleAddOutcomeToBasket
    ]
  )

  const outcomeMode = useEventMode({ eventProbability })

  return (
    <StyledEventCardProbabilitiesItemWrapper>
      <StyledEventCardProbabilitiesItem
        key={getBetOutcomeId({
          eventId: event.id,
          outcomeTypeId: eventProbability.outcomeTypeId,
          parameters: eventProbability.parameters
        })}
        $mode={outcomeMode}
        isSuspended={isSuspended}
        onClick={onClickAddOutcomeToBasket}
      >
        {!!eventProbability.outcomeTypeId && (
          <>
            <StyledEventCardProbabilitiesItemCode $mode={outcomeMode}>
              {outcomeTypes[eventProbability.outcomeTypeId]?.shortName}
            </StyledEventCardProbabilitiesItemCode>
            <StyledEventCardProbabilitiesItemValue $mode={outcomeMode}>
              {eventProbability.odd || '-'}
            </StyledEventCardProbabilitiesItemValue>
          </>
        )}
      </StyledEventCardProbabilitiesItem>
    </StyledEventCardProbabilitiesItemWrapper>
  )
}
