import { useRef, useState, useEffect } from 'react'

import { CarouselApi } from 'shared/ui/Carousel'

export const useCarouselScroll = (events) => {
  const carouselApi = useRef<CarouselApi>()
  const [shouldScroll, setShouldScroll] = useState(false)

  const scrollToIndex = () => {
    setShouldScroll(true)
  }

  useEffect(() => {
    if (shouldScroll) {
      carouselApi.current?.scrollTo(0)
      setShouldScroll(false)
    }
  }, [shouldScroll, events])

  return {
    carouselApi,
    scrollToIndex
  }
}
