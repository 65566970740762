import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FoundEntity } from 'betweb-openapi-axios'
import {
  selectFetchItemsError,
  selectInputSearch,
  selectSearchDataItems
} from 'astra-core/containers/SearchProvider'

import { ClearResultSearch } from 'pages/page-live-events/Line/components/ui'

import {
  StyledSearchItem,
  StyledSearchItemLive,
  StyledSearchList,
  StyledSearchListHeaderText
} from './SearchList.styled'
import { SearchListProps } from './SearchList.types'

const Item = (item: FoundEntity) => {
  const link = useMemo(
    () =>
      item.live
        ? `/live/${item.sportId}/${item.eventId}`
        : `/line/${item.sportId}/${item.tournamentId}/${item.eventId}`,
    [item.eventId, item.live, item.sportId, item.tournamentId]
  )

  return (
    <StyledSearchItem to={link}>
      {item.name}
      {item.live ? <StyledSearchItemLive>LIVE</StyledSearchItemLive> : null}
    </StyledSearchItem>
  )
}

export const SearchList = ({ isMainPage }: SearchListProps) => {
  const [t] = useTranslation()

  const items = useSelector(selectSearchDataItems)
  const valueSearch = useSelector(selectInputSearch)
  const error = useSelector(selectFetchItemsError)

  if (!valueSearch?.length) return null

  if (!items.length) {
    if (!error) {
      return (
        <StyledSearchList>
          <ClearResultSearch isMainPage={isMainPage} />
        </StyledSearchList>
      )
    }
    return null
  }

  return (
    <StyledSearchList>
      <StyledSearchListHeaderText
        dangerouslySetInnerHTML={{
          __html: t('showing the most relevant result', { value: items.length })
        }}
      />
      {items.map((item) => (
        <Item key={item.eventId} {...item} />
      ))}
    </StyledSearchList>
  )
}
