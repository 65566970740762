import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconStats: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M4 6.83325C4.55228 6.83325 5 7.23072 5 7.99992L5 13.8333C5 14.6025 4.55228 14.9999 4 14.9999C3.44772 14.9999 3 14.6025 3 13.8333L3 7.99992C3 7.23072 3.44772 6.83325 4 6.83325Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8 1C8.55228 1 9 1.38796 9 2.16667V13.8333C9 14.612 8.55228 15 8 15C7.44772 15 7 14.612 7 13.8333V2.16667C7 1.38796 7.44772 1 8 1Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 3.33325C12.5523 3.33325 13 3.7257 13 4.49992L13 13.8333C13 14.6075 12.5523 14.9999 12 14.9999C11.4477 14.9999 11 14.6075 11 13.8333L11 4.49992C11 3.7257 11.4477 3.33325 12 3.33325Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
