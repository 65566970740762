import styled, { css } from 'styled-components'

export const StyledSearchWrapper = styled.div<{ isMainPage?: boolean }>`
  display: flex;

  ${({ isMainPage }) =>
    isMainPage
      ? css`
          padding: 8px 0 8px 16px;
        `
      : css`
          padding: 16px 0 8px 16px;
        `}
`
export const StyledWrapper = styled.div``
