import styled from 'styled-components'

import { Notification } from 'widgets/notification'

export const StyledOnboardingNotification = styled(Notification)`
  margin-bottom: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
`
