import styled from 'styled-components'

import { Input } from 'shared/ui/Input'

export const StyledSearchWrapper = styled.div`
  align-content: baseline;
  background-color: ${(props) => props.theme.colors.default.primary[0]};
  display: flex;
  flex: 1;
  flex-direction: column;
`
export const StyledSearchInputHeaders = styled.div`
  padding: 16px 16px 4px;
`

export const StyledSearch = styled.div`
  position: relative;
  width: 440px;
`

export const StyledSearchInput = styled(Input)`
  background-color: ${(p) => p.theme.colors.default.primary[5]};
  border: 1px solid ${(props) => props.theme.colors.custom.primary[8]};
  width: 440px;

  &:focus,
  &:not([value=''], :focus) {
    background-color: initial;
    border-color: ${({ theme }) => theme.colors.custom.primary[49]};
  }
`

export const StyledIconRightButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  outline: none;
  padding: 0 8px 0 4px;
  position: absolute;
  right: 0;
  top: -1px;
`
