import styled from 'styled-components'

import { Carousel } from 'shared/ui/Carousel'

export const StyledMainPageEventsWrapper = styled.div`
  display: grid;
`

export const StyledMainPageEventsHeaderWrapper = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  & > a {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 67px;
  }
`

export const StyledMainPageEventsHeader = styled.span`
  color: ${(p) => p.theme.colors.default.primary[90]};
  flex-grow: 1;
  font-size: ${(p) => p.theme.fonts.size.xl};
  font-weight: ${(p) => p.theme.fonts.weight.bold};
`

export const StyledMainPageEventsControlsWrapper = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding-left: 0.5rem;

  & > button {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }
`

export const StyledEventsTable = styled.div`
  display: grid;
`

export const StyledEventsCarousel = styled(Carousel)`
  padding: 0.5rem 1rem 1rem;
`
