import React, { forwardRef } from 'react'

import { getCompetitorsNames } from 'shared/lib/events'
import { TeamLogo } from 'shared/ui/Icon/TeamLogo'
import { ETeamLogo } from 'shared/ui/Icon/TeamLogo/TeamLogo.types'

import {
  CurrentServerFlag,
  StyledEventCardCompetitor
} from '../../EventCard.styled'

import { EventCardCompetitorProps } from './EventCardCompetitor.types'

export const EventCardCompetitor = forwardRef<
  HTMLDivElement,
  EventCardCompetitorProps
>(({ competitors, isServer, isLongTeamName }, ref) => (
  <StyledEventCardCompetitor isLongTeamName={!!isLongTeamName} ref={ref}>
    {isServer && <CurrentServerFlag />}
    <TeamLogo
      isPlace={ETeamLogo.mainPage}
      logoUrl={competitors[0]?.competitorLogo?.logoUrl}
    />
    {getCompetitorsNames(competitors)}
  </StyledEventCardCompetitor>
))
