import styled from 'styled-components'

export const CarouselItem = styled.div`
  scroll-snap-align: start;
`

export const CarouselWrapper = styled.div`
  -ms-overflow-style: none;
  column-gap: 0.5rem;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  overflow: auto;
  padding: 0 1rem;
  position: relative;
  scroll-padding: 0 1rem;

  /* Remove scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory; /* IE and Edge */
  scrollbar-width: none;
`
