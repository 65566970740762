import { Sport } from 'betweb-openapi-axios'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { SelectSportPayload } from 'og-core/providers/main-page/types'
import { EventEnriched } from 'astra-core/containers/EventsProvider'

import { RootState } from 'shared/types/store'

import type { IconProps } from 'shared/ui/Icon'
import type { FC } from 'react'

export enum EMainPageEventsRepresentation {
  TABLE = 'TABLE',
  CAROUSEL = 'CAROUSEL'
}

export enum EMainPageEventsList {
  TOP_LIVE = 'TOP_LIVE',
  TOP_LINE = 'TOP_LINE',
  UPCOMING = 'UPCOMING'
}

export interface MainPageEventsProps {
  mainPageEventsListType: EMainPageEventsList
}

export type MainPageEventsConfiguration = Record<
  EMainPageEventsList,
  {
    selectCurrentSport: (state: RootState) => number
    selectSports: (state: RootState) => Sport[]
    selectEvents: (state: RootState) => EventEnriched[]
    selectLoading: (state: RootState) => boolean | undefined
    setCurrentSport: ActionCreatorWithPayload<SelectSportPayload>
    untranslatedTitle: string
    icon?: FC<IconProps>
    representation: EMainPageEventsRepresentation
    link: string
    selectIsEventsIdsBySport: (state: RootState) => boolean
  }
>
