import React, { FC } from 'react'

import { IconStats } from 'shared/ui/Icon/General/IconStats'
import { EColorsNames } from 'shared/types/theme'
import { EventFavouritesStarButton } from 'widgets/event-favourites-star-button'

import { EventCardButtonsProps } from './EventCardButtons.types'
import { StyledEventCardButtonsWrapper } from './EventCardButtons.styled'

export const EventCardButtons: FC<EventCardButtonsProps> = ({ event }) => {
  return (
    <StyledEventCardButtonsWrapper>
      <IconStats
        colorProps={{ name: EColorsNames.Primary, value: 50 }}
        size={15}
      />

      {event?.id && (
        <EventFavouritesStarButton
          colorProps={{ name: EColorsNames.Primary, value: 50 }}
          eventId={event?.id}
          size={15}
          withStopPropagation
        />
      )}
    </StyledEventCardButtonsWrapper>
  )
}
